<template>
  <el-dialog :title="isEdit ? '编辑类目' : '新增类目'" width="450px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form label-width="80px" :model="form" :rules="rules" ref="form">
      <el-form-item label="类目名称" prop="categoryName">
        <el-input placeholder="请输入" v-model="form.categoryName" clearable></el-input>
      </el-form-item>
      <el-form-item label="类目编码" prop="categoryCode">
        <el-input placeholder="请输入" v-model="form.categoryCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input placeholder="请输入" v-model="form.sort" clearable type="number"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import api from '@/api/operate/appCategory'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
    parentId: {
      default: 0,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        categoryCode: [{ required: true, message: '请输入类目编码', trigger: 'blur' }],
        categoryName: [{ required: true, message: '请输入类目名称', trigger: 'blur' }],
      },
      isEdit: false,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail)
        this.form = this.isEdit
          ? this.detail
          : {
              parentId: this.parentId,
            }
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = this.form
        let handleType = this.isEdit ? 'modify' : 'add'
        api[handleType](params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
  },
}
</script>
