<!-- 应用类目 -->
<template>
  <common-layout class="h100">
    <template #header>
      <el-form inline>
        <el-form-item label="名称">
          <el-input placeholder="请输入" v-model="form.categoryName" clearable></el-input>
        </el-form-item>
        <el-form-item label="编码">
          <el-input placeholder="请输入" v-model="form.categoryCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.useStatus" clearable>
            <el-option label="已启用" :value="true"></el-option>
            <el-option label="已禁用" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
          <el-button type="primary" plain @click="handleAdd(curRow)">新增</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template>
      <div class="flex h100">
        <div class="border-right-line" style="width: 250px">
          <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick" highlight-current></el-tree>
        </div>
        <el-table :data="tableData" v-loading="tableLoading" border stripe height="100%" class="flex-1 ml10" style="width: 0">
          <el-table-column label="类目编码" prop="categoryCode" align="center" min-width="180"></el-table-column>
          <el-table-column label="类目名称" prop="categoryName" align="center" min-width="180"></el-table-column>
          <el-table-column label="排序" prop="sort" align="center" min-width="100"></el-table-column>
          <el-table-column prop="useStatus" label="状态" align="center" min-width="100">
            <template slot-scope="scope">
              <span :class="scope.row.useStatus ? 'enable-status' : 'disable-status'">{{ scope.row.useStatus ? '已启用' : '已禁用' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建账号" prop="createUser" align="center" min-width="100"></el-table-column>
          <el-table-column label="创建时间" prop="createTime" align="center" min-width="180"></el-table-column>
          <el-table-column label="更新账号" prop="updateUser" align="center" min-width="100"></el-table-column>
          <el-table-column label="更新时间" prop="updateTime" align="center" min-width="180"></el-table-column>
          <el-table-column label="操作" align="center" min-width="180" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd(scope.row)"> 新增 </el-button>
              <el-button type="text" @click="handleEdit(scope.row)"> 编辑 </el-button>
              <el-button type="text" @click="handleEditStatus(scope.row)"> {{ scope.row.useStatus ? '禁用' : '启用' }} </el-button>
              <el-button type="text" @click="handleDelete(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template #footer>
      <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </template>
    <add-modal :show.sync="showAdd" :detail="curRow" :parentId="parentId" @close="closeModal" />
  </common-layout>
</template>

<script>
import api from '@/api/operate/appCategory'
import addModal from './addModal'
export default {
  components: { addModal },
  data() {
    return {
      form: {},
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      tableData: [],
      tableLoading: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'categoryName',
      },
      showAdd: false,
      curRow: null,
      parentId: 0,
    }
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      this.curRow = null
      this.getTree()
      this.getList()
    },
    getTree() {
      let params = {
        ...this.form,
      }
      api.getTree(params).then((res) => {
        this.treeData = res.data
      })
    },
    getList() {
      let params = {
        ...this.form,
        ...this.page,
      }
      if (this.curRow) {
        if (this.curRow.children && this.curRow.children.length) {
          params.parentId = this.curRow.id
        } else {
          params.id = this.curRow.id
        }
      }
      this.tableLoading = true
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAdd(row) {
      if (row) {
        this.parentId = row.id
      } else {
        this.parentId = 0
      }
      this.curRow = null
      this.showAdd = true
    },
    handleEdit(row) {
      this.curRow = _.cloneDeep(row)
      this.showAdd = true
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
    handleEditStatus(row) {
      this.$confirm(`确定${row.useStatus ? '禁用' : '启用'}此数据吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let params = _.cloneDeep(row)
        params.useStatus = !params.useStatus
        api.modify(params).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleNodeClick(val) {
      this.curRow = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
  },
}
</script>
<style lang="scss" scoped></style>
